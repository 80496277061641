/* RESET CSS*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; /* 1rem = 10px */
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 1rem 0;
}

h1 {
  font-size: 3.6rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

p {
  font-size: 1.6rem;
  margin: 1rem 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
