/* -----------------------------------------
                    RESET
-------------------------------------------*/
@import url("./reset.css");

/* -----------------------------------------
                    VARIABLES DE COULEURS
         ET POLICES                             
-------------------------------------------*/
:root {
  --vert: #009c91;
  --bleu: #2980b9;
  --bleufonce: #2c3e50;
  --red: #d04022;
  --grisfonce: #cbcbcc;
}
/* import des polices */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital@1&family=Zen+Dots&display=swap");
/* Titres */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Pacifico, cursive;
  font-weight: normal;
  color: #333;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

h1 {
  font-size: 5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

h2 {
  font-size: 4.5rem;
  line-height: 1.3;
  margin-bottom: 0.8rem;
}

h3 {
  font-size: 4rem;
  line-height: 1.4;
  margin-bottom: 0.6rem;
}

h4 {
  font-size: 3rem;
  line-height: 1.5;
  margin-bottom: 0.4rem;
}

h5 {
  font-size: 2.5rem;
  line-height: 1.6;
  margin-bottom: 0.3rem;
}

h6 {
  font-size: 2rem;
  line-height: 1.7;
  margin-bottom: 0.2rem;
  font-weight: bold;
}

/* Paragraphes */
p {
  font-size: 2rem;
  line-height: 1.5;
  color: #333;
  font-family: "Lato", sans-serif;
}
a {
  font-family: "Lato", sans-serif;
}


/* -----------------------------------------
                Menu
-------------------------------------------*/
#navigation {
  background-color: var(--grisfonce);
  height: 100%;
}

.navigation_menu {
  height: 4rem;
  color: #000;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: white;
  padding: 1rem 0 1rem 5rem;
  font-size: 2.2rem;
  background-position: left;
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position-x: 1rem;
  transition: opacity 0.5s ease;
}
.menu {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 60px; /* Largeur initiale du menu rétracté */
  background-color: #f1f1f1;
  padding: 10px;
  /* Transition fluide de la largeur */
  transition: width 0.5s ease;
}

/*animation menu */
.navigation_menu:hover {
  background-color: var(--vert);
}

.navigation_menu:active {
  background-color: var(--vert);
}
/* Style lorsque le menu est déployé */
.menuRetractable {
  width: 22rem; /* Largeur du menu déployé */
}
/* opacité pour les span au moment ou le menu est retracté */
.navigation_menu span {
  opacity: 0;
  transition: opacity 0.2s ease;
}
/* opacité pour les span au moment ou le menu est ouvert */
.menuRetractable .navigation_menu span {
  opacity: 1;
  transition: opacity 0.2s ease;
}

/* bouton du menu */
.toggle_button {
  position: absolute;
  margin-top: -11.2rem;
  color: var(--grisfonce);
  font-size: 4rem;
  margin-bottom: 1.3rem;
  margin-left: 2rem;
}
/* icones de l'entete */
.fa-lg {
  font-size: 1.45em;
  line-height: 0.05em;
  margin: 1rem;
}
/* -----------------------------------------
                Contenu
-------------------------------------------*/

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  transition: all ease-in-out 0.5s;
  z-index: 9997;
  transition: all 0.5s;
  padding: 0 15px;
  background: #040b14;
  overflow-y: auto;
}

#header .profile img {
  margin: 15px auto;
  display: block;
  width: 120px;
  border: 8px solid #2c2f3f;
}

#header .profile h1 {
  font-size: 24px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  -moz-text-align-last: center;
  text-align-last: center;
  font-family: "Poppins", sans-serif;
}

#header .profile h1 a,
#header .profile h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .profile .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #212431;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#header .profile .social-links a:hover {
  background: #149ddd;
  color: #fff;
  text-decoration: none;
}
/* pour décaler le contenu et l'éloigner du aside*/
article,footer {
  margin-left: 300px;
}

.container,
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 1199px) {
  #header {
    left: -300px;
  }

  article {
    margin-left: 0;
  }

  .container,
  .container-fluid {
    padding-left: 12px;
    padding-right: 12px;
  }
}
/*--------------------------------------------------------------
# About Me
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.about .content ul strong {
  margin-right: 10px;
}

.about .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #149ddd;
  line-height: 0;
}

.about .content p:last-child {
  margin-bottom: 0;
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu {
  padding: 30px 0 0 0;
}

.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu>ul>li {
  position: relative;
  white-space: nowrap;
}

.nav-menu a,
.nav-menu a:focus {
  display: flex;
  align-items: center;
  color: #a8a9b4;
  padding: 12px 15px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
}

.nav-menu a i,
.nav-menu a:focus i {
  font-size: 24px;
  padding-right: 8px;
  color: #6f7180;
}

.nav-menu a:hover,
.nav-menu .active,
.nav-menu .active:focus,
.nav-menu li:hover>a {
  text-decoration: none;
  color: #fff;
}

.nav-menu a:hover i,
.nav-menu .active i,
.nav-menu .active:focus i,
.nav-menu li:hover>a i {
  color: #149ddd;
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  background-color: #149ddd;
  color: #fff;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border-radius: 50px;
  cursor: pointer;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active #header {
  left: 0;
}

/*--------------------------------------------------------------
# Akills
--------------------------------------------------------------*/
.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #050d18;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #dce8f8;
  height: 10px;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #149ddd;
}


/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 35px auto;
  list-style: none;
  text-align: center;
  background: #fff;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #272829;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #149ddd;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  opacity: 1;
  left: 0;
  right: 0;
  bottom: -60px;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
  display: flex;
  justify-content: center;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  font-size: 28px;
  text-align: center;
  background: rgba(20, 157, 221, 0.75);
  transition: 0.3s;
  width: 50%;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  background: rgba(20, 157, 221, 0.95);
}

.portfolio .portfolio-wrap .portfolio-links a+a {
  border-left: 1px solid #37b3ed;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-links {
  opacity: 1;
  bottom: 0;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #149ddd;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #149ddd;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(5, 13, 24, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}


/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  /* overflow: hidden; */
}

.section-bg {
  background: #f5f8fd;
}

.section-title {
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #173b6c;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #149ddd;
  bottom: 0;
  left: 0;
}

.section-title p {
  margin-bottom: 0;
}




.resume .resume-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #050d18;
}

.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid #1f5297;
  position: relative;
}

.resume .resume-item h4 {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #050d18;
  margin-bottom: 10px;
}

.resume .resume-item h5 {
  font-size: 16px;
  background: #e4edf9;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #1f5297;
}









/* partie page de pré-acceil */
.pre-accueil,.pre-accueil section {
  height: 70%;
  width: 50%;
  display: flex;
  flex-direction:column;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  animation: accueil 2s cubic-bezier(0.7, 0, 0.84, 0) 0s 1 normal forwards;
}
/* pour que le texte de la machine à écrire ne bouge pas le bouton */
.pre-accueil section div{
  height:10rem;
}
.pre-accueil h1,
.pre-accueil h2 {
  color: var(--bleufonce);
  animation: daragan 2s ease 2s 1 normal forwards;
}
.pre-accueil h3 {
  color: var(--bleufonce);
  font-size: 6rem;
}
.pre-accueil img {
  margin-left: 10%;
  width:20rem;
}
.portrait-container {
  position: relative;
  width: 40%;
  float: right;
  margin-right: 5%; /* Utiliser des pourcentages pour le décalage horizontal */
}
/* forme bleu de l'hexagone */
.hexagone-haut {
  height: 100%;
  width: 100%;
  position: absolute;
  top: -10%; /* position verticale */
  right: -5%; /* position horizontal */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background-color: var(--bleu);
  z-index: -1;
  overflow: hidden;
}

.portrait-accueil {
  width: 100%;
  height: 100%;
  position: relative;
}

.portrait-accueil img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  position: relative;
  z-index: 1;
}

.ouverturesite {
  background-color: #fff !important;
  width: 20rem;
  height: 5rem;
  color: #fff;
  text-transform: uppercase;
  margin-left: 40%;
}

@keyframes accueil {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes daragan {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateX(48px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateX(26px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateX(13px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateX(6.5px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateX(4px);
  }

  25%,
  55%,
  75%,
  87%,
  98% {
    animation-timing-function: ease-out;
    transform: translateX(0px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateX(0px);
  }
}
/* fin preaccueil */
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
article {
  grid-row: 2;
  grid-column: 2 / -1;
  padding: 2rem;
  background-image: url('../../public/Images/Design/Regular_hexagon.svg.png');
  background-size:auto;
  background-repeat:no-repeat;
  /* position de l'héxagone */
  background-position: -100% 10%; 
  /* Pour Masquer le fond qui dépasse */
  background-clip: content-box; 
}
/* grille de des contenus compétences et projets */
.grid-col {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: max-content;
  align-items: stretch;
  gap: 20px;
  padding-left: 2rem;
  padding-right: 2rem;
}
/* alignement des contenus à l'intérieur des div en hauteur */
.grid-col > * {
  align-self: stretch;
}
.grid-col > div {
  display: flex; /* Ajout de cette propriété pour aligner les infos sur toute la hauteur */
  flex-direction: column; /* mise en colonne des contenus */
  justify-content: space-between;
}
.grid-col > div figure {
  flex: 1; /*  Permet à l'image de prendre toute la hauteur disponible dans le conteneur flex */
  margin: 0; /* Suppression des marges par défaut pour éviter des espaces inutiles */
}
.grid-proj {
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
}
/* champs de filtrage dans la partie projet */
.search {
  width: 20%;
height: 5rem;
margin-left: 75%;
border-radius: 30px;
border-color: #000;
margin-bottom: 2rem;
}
/* séparation entre l'arrondi de l'input et le texte placeholder de l'input */
input#filterProject.search {
  padding: 1rem;
}
/* Bouton d'effacement du formulaire de recherche */
.clearSearch {
  position: absolute;
  margin-left: -3rem;
  margin-top: 2rem;
  animation: clearbtn 0.5s linear 0s 0.5 normal forwards;
}
.maskedClear {
  transition: opacity 0.7s ease;
  animation: btnFiltre 3s ease-in 0s 1 normal forwards;
}

img {
  max-width: 100%;
  text-align: center;
}
figure {
  max-width: 90%;
  text-align: center;
}
figcaption {
  background-color: var(--vert);
  border-radius: 10%;
  padding: auto;
  color: #fff;
  width: fit-content;
  padding: 0.5rem;
}
.profil {
  background-color: var(--grisfonce);
  color: #fff;
  width: min(100%, 1170px);
  box-shadow: 0 5px 13px 1px rgba(0, 0, 0, 0.3);
  margin: 1rem auto;
  text-align: center;
}
.profil h5 {
  text-align: center;
  padding-top: 2rem;
}
.profil p {
  justify-content: center;
  text-align: justify;
  padding: 40px;
}

.presentation {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: fit-content;
}
.presentation img {
  width: min(520px, 550px);
  align-items: center;
}
.presentation div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 3rem;
}
.presentation figure {
  display: flex;
  text-align: center;
}
.presentation .svg-inline--fa {
  font-size: 4rem;
}


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  padding-bottom: 130px;
}

.contact .info {
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .info i {
  font-size: 20px;
  color: #149ddd;
  float: left;
  width: 44px;
  height: 44px;
  background: #dff3fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #050d18;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #173b6c;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #149ddd;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #149ddd;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 15px;
}

.contact .php-email-form label {
  padding-bottom: 8px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #149ddd;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #37b3ed;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* -----------------------------------------
                boutons
-------------------------------------------*/
button:not(.toggle_button) {
  margin: 1rem;
  width: fit-content;
  box-shadow: inset 0px 1px 0px 0px #bee2f9;
  border-radius: 6px;
  border: 1px solid #3866a3;
  display: inline-block;
  cursor: pointer;
  color: #14396a;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #7cacde;
}
button:hover:not(.toggle_button) {
  background: linear-gradient(
    to bottom,
    var(--grisfonce) 5%,
    var(--grisfonce) 100%
  );
}
.button-primary {
  background: linear-gradient(to bottom, var(--bleu) 5%, var(--bleu) 100%);
  color: #fff !important;
}
.button-secondary {
  background: linear-gradient(to bottom, var(--red) 5%, var(--red) 100%);
  color: #fff !important;
}

.error {
  color: var(--red);
  font-weight: bold;
}
/* partie accordeon en page compétences */
.otherskills {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.skill-details ul {
  list-style: circle;
  font-size: 3rem;
  margin: 2rem 5rem;
}
.skill {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-top: 4px solid transparent;
  gap: 1rem;
  margin-top: 15px;
}
.skill-header {
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}


.skill-header h4 {
  text-transform: uppercase;
}

/* masquage du contenu du détail */
.skill-details {
  overflow: hidden;
  height: 0;
}

.open {
  border-color: var(--vert);
}
.open .skill-header {
  color: var(--vert);
}
.open .skill-details {
  height: auto;
}
/* fleche de retractation */
.accor-fleche{
  animation: fleche 1s ease 1s 1 normal forwards;
}

@keyframes fleche {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(180deg);
	}
}
/* fin de l'accordéon compétences */

/* -----------------------------------------
                Footer
-------------------------------------------*/
footer {
  background-color: var(--bleu);
  padding: 3rem;
  text-align: center;
  grid-column: 1 / -1;
  color: #fff !important;
}
footer a,
footer p,
footer h2,
footer h5 {
  color: #fff;
}
footer a,
footer .logo {
  margin-left: 3rem;
  font-size: 3rem;
}
footer img {
  width: 20rem;
}
.footer-identity {
  display: flex;
}
/* gestion des cookies */
.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 2px solid var(--vert);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-cookies {
  color: #fff;
}
/* fin de gestion des cookies */

/* -----------------------------------------
                Responsive
-------------------------------------------*/
@media screen AND (max-width: 600px) {
  .grid-global,
  .grid-proj {
    grid-template-columns: 1fr;
  }
  article {
    grid-row: 3;
    grid-column: 1;
  }
  .entete {
    display: flex;
    flex-direction: column;
  }
  /* gestion des icones de réseaux sociaux */
  .entete nav {
    margin-right: auto;
    color: #fff;
    font-size: 3rem;
  }
  #navigation {
    grid-row: 2;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
  .navigation_menu span {
    opacity: 1;
    transition: opacity 0.5s ease;
    color: #000;
  }
  .search {
    width: 80%;
    height: 5rem;
    margin-left: 0;
  }

  /* disparition de l'image de la page présentation absente et menu retracté  */
  .presentation figure,
  .menuRetractable,
  .entete div {
    display: none;
  }
  .profil figure,
  .logo:not(.pre-acc .logo),
  .search,
  .entete span {
    margin-left: auto;
    margin-right: auto;
  }
  .profil,
  .presentation,
  .profil button,
  .maskedSpan,
  .footer-identity,
  footer nav,.acc-pre {
    display: block;
  }
  /* portrait de la page d'accueil en responsive et reste de la page d'accueil */
  .portrait-container{
    margin-top:5rem;
  }
  /* aggrandissement de l'accueil en mode responsive */
  .pre-accueil,  .pre-accueil section {
    width:100%;
  }
  .pre-accueil img{
    margin-left:0;
  }
  .acc-pre {
    display:flex;
    flex-direction: column;
    width:100%;
    align-items: center;
  }
  .acc-pre .logo{

  }
}

/* -----------------------------------------
                Animations keyframes
-------------------------------------------*/
@keyframes clearbtn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes btnFiltre {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
